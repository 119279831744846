<template>
  <div id="app">
    <web-head></web-head>
    <router-view/>
    <web-foot></web-foot>
  </div>
</template>




<script>
  import head from './components/head'
  import foot from './components/foot'
export default {
  data(){
    return{
      
    }
  },
  created(){
    this.$api.post('common.jshopconf',{},(res)=>{
      this.$local.set('shop',JSON.stringify(res.data))
      // if(res.data.status){

      // }else{

      // }
    })
  },
    components:{'web-head':head,'web-foot':foot}
}
</script>







<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.center{
  margin: 0 auto;
}

.main{
        padding: 30px;
        margin: 40px auto;
        width: 1140px;
        background: #F1F4F8;
        opacity: 1;
        border-radius: 30px;
    }
    .title{
       text-align: left;
       font-size: 26px; 
       color: #144591;
    }

.text_left{
  text-align: left;
}

  .mask{
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      height: 100%;
      width: 100%;
      background: rgba($color: #000000, $alpha: .3);
      display: flex;
      align-items: center;
      justify-content: center;
  }



  

</style>
