import axios from 'axios'
import * as local from './local'
import { Message } from 'element-ui';

//微信开放平台
var appid = 'wx8f65a92038cc79d3'
var appsecred = '64951275f5790d60d1147f0a811f5b58'
var return_uri = 'https://www.kmdnkj.com/PC/#/mine' //微信登陆回调地址
// var return_uri = 'http://www.kmdnkj.com/PC/#/mine' //测试用微信登陆回调地址


// 忘记哪个功能的回调链接了
const return_url = '//www.kmdnkj.com/PC/#/'

// 更换域名的时候 不要忘了更换 图片上传（/entry_major,/edit） 和  文件上传（/shenhe）的域名
var instance = axios.create({
    baseURL : '//www.kmdnkj.com/api/index/index/method/',
    timeout : 5000,
    changeOrigin:true
    // headers : {
    //     "Authori-zation":'Bearer ' + localStorage.getItem('token'),
    //     'Content-Type': 'application/x-www-form-urlencoded'
    // }
})
// 通过code获取access_token 和 refresh_token
// https://api.weixin.qq.com/sns/oauth2/access_token?appid=wx8f65a92038cc79d3&secret=64951275f5790d60d1147f0a811f5b58&code=031Tj3000kVeZL1TMA300UfLY24Tj30N&grant_type=authorization_code
// 通过refresh_token 获取 openid
// https://api.weixin.qq.com/sns/oauth2/refresh_token?appid=wx8f65a92038cc79d3&grant_type=refresh_token&refresh_token=46_0QFX7W4tk5XcdJsaCfrYjSIbstfs6DvsUH8PFJNn5VwStJJQSNWwSJkBNgTcfxNMkRqJGTqSEsPud9e4Z-ShgdZUPUdNnMel5jepdA7qsqc
const post = function(url,data,callback){
    instance.post(url,data).then((res)=>{
        callback(res)
    })
}
const userpost= function(url,data,callback){
    if(local.get('token')){
        let newdata = data
        newdata.token=local.get('token')
        instance.post(url,newdata).then((res)=>{
                callback(res)
            })
    }else{
        Message.error('请登录')
        // setTimeout(()=>{
        //     router.push({path:'/mine'})
        // },2000)
        
    }
   
}

export {
    post,
    userpost,
    return_url,
    appid,
    appsecred,
    return_uri
}
