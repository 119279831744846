import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue')
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import('../views/mine.vue')
  },
  {
    path: '/vip',
    name: 'vip',
    component: () => import('../views/vip.vue'),
    meta:{
      title:'会员中心'
    }
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/order.vue'),
    meta:{
      title:'订单管理'
    }
  },
  {
    path: '/record',
    name: 'record',
    component: () => import('../views/record.vue'),
    meta:{
      title:'接单记录'
    }
  },
  {
    path: '/shenhe',
    name: 'shenhe',
    component: () => import('../views/shenhe.vue'),
    meta:{
      title:'标书审查'
    }
  },
  {
    path: '/major',
    name: 'major',
    component: () => import('../views/major.vue'),
    meta:{
      title:'律师专家'
    }
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('../views/chat.vue'),
    meta:{
      title:'专家咨询'
    }
  },
  {
    path: '/edit',
    name: 'edit',
    component: () => import('../views/edit.vue'),
    meta:{
      title:'用户中心'
    }
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/pay.vue'),
    meta:{
      title:'支付'
    }
  },
  {
    path: '/entry_major',
    name: 'entry_major',
    component: () => import('../views/entry_major.vue'),
    meta:{
      title:'专家入驻'
    }
  },
  {
    path: '/artical',
    name: 'artical',
    component: () => import('../views/artical.vue'),
    meta:{
      title:'文章详情'
    }
  },
  {
    path: '/artical_list',
    name: 'artical',
    component: () => import('../views/artical_list.vue'),
    meta:{
      title:'法律法规'
    }
  },
  {
    path: '/result',
    name: 'result',
    component: () => import('../views/result.vue'),
    meta:{
      title:'支付结果'
    }
  },
  {
    path: '/majorMessage',
    name: 'majorMessage',
    component: () => import('../views/majorMessage.vue'),
    meta:{
      title:'详情'
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
