import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as local from './config/local'
import * as api from './config/api'



Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$local = local

Vue.prototype.$api = api
// Vue.api = Vue.prototype.$api = api



router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
