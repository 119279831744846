<template>
  <div class="home">

    
  <div class="banner">
    <img src="../assets/banner.png" alt="" srcset="">
  </div>

  <div class="message_box">
    <div class="message_item" v-for="(item,index) in artical_list" :key='index'>
      <img :src="item.list[0].cover" alt="">
      <div class="message_list">
        <ul class="ul-style">
          <li class="li-style" v-for="(item,index) in item.list" :key='index' @click="jump(item)">
            {{item.title}}
            <div class="time_style">{{item.ctime}}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
    <!-- 分割线 -->
   <!-- <el-divider content-position="center" class="divider-style"> <div style="font-size:20px;color:#194C90;"><b>服务优势</b></div></el-divider> -->
 

  <div class="service-box">
    <img src="../assets/detail.png" alt="" srcset="">
    <!-- <div class="service_two">

      <div class="service_message">

        <div class="service-title">
          <img src="" alt="" srcset="">
          <div class="service_right">
            <div class="right-title">专业专注</div>
            <div class="right-english-title">PROFESSIONAL FOCUS</div>
            <hr>
            <div class="right-bottom">
              为您配备专属业务顾问，全称免费提供也以为资讯，为您的企业排忧解难
            </div>
          </div>
        </div>
        <div class="service-title">
          <img src="" alt="" srcset="">
          <div class="service_right">
            <div class="right-title">专业专注</div>
            <div class="right-english-title">PROFESSIONAL FOCUS</div>
            <hr>
            <div class="right-bottom">
              为您配备专属业务顾问，全称免费提供也以为资讯，为您的企业排忧解难
            </div>
          </div>
        </div>
      </div>
      <img src="" alt="" class="service_img">
    </div>

      <div class="service_two">
        <img src="" alt="" class="service_img">
      <div class="service_message">
        <div class="service-title">
          <img src="" alt="" srcset="">
          <div class="service_right">
            <div class="right-title">专业专注</div>
            <div class="right-english-title">PROFESSIONAL FOCUS</div>
            <hr>
            <div class="right-bottom">
              为您配备专属业务顾问，全称免费提供也以为资讯，为您的企业排忧解难
            </div>
          </div>
        </div>
        <div class="service-title">
          <img src="" alt="" srcset="">
          <div class="service_right">
            <div class="right-title">专业专注</div>
            <div class="right-english-title">PROFESSIONAL FOCUS</div>
            <hr>
            <div class="right-bottom">
              为您配备专属业务顾问，全称免费提供也以为资讯，为您的企业排忧解难
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>



  <!-- 底部 -->
  

  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
  data(){
    return{
       artical_list:[]
    }
  },
  created(){
    this.loadArticle()
  },
  mounted(){

  },
  methods:{
     loadArticle(){
         for(let i = 3 ;i<6  ;i++){
				let data={
					type_id:i
				}
				this.$api.post('articles.getArticleList',data,(res)=>{
				if(res.data.status){
					let new_object = {}
					new_object.type_id = i
					new_object.type_name = res.data.data.type_name
          new_object.list = res.data.data.list
         this.artical_list.push(new_object)
        console.log(this.artical_list)
				}else{
					this.$message.error(res.data.msg)
				}
				})
			}
     },
     jump(item){
        this.$router.push({path:'/artical',query:{id:item.id}})
     }
  }
  
}
</script>

<style lang="scss" scoped>
  

  .banner{
    margin: 0 auto;
    margin-top: 45px;
    height: 196px;
    width: 1200px;
  }

  .message_box{
    margin: 0 auto;
    margin-top: 60px;
    height: 410px;
    width: 1200px;
    display: flex;
    justify-content: space-between;
  }

  .message_item{
    height: 410px;
    width: 380px;
    box-shadow: 0px 3px 10px rgba(153, 153, 153, 0.16);
  }

  .message_item img{
    height: 170px;
    width: 380px;
    background-color: darkgoldenrod;
  }

  .message_list{
    width: 361px;
    height: 207px;
    margin: 0 auto;
    overflow: auto;
  }
  
  .ul-style{
    margin-left: 20px;
    padding-left: -20px;
  }

  .li-style{
    margin-top: 25px;
    text-align: left;
    font-size: 16px;
    color: #000;
  }

  .time_style{
    text-align: right;
    color: #999;
    font-size: 16px;
  }

  .divider-style{
    margin: 0 auto;
    margin-top: 100px;
    width: 500px;
  }
  
  .service-box{
    margin: 0 auto;
    margin-top: 50px;
    text-align: center;

  }

  .service_two{
    display: flex;
    height: 410px;
  }
  .service_message{
    width: 50%;
  }
  .service_img{
    width: 50%;
    background-color: seagreen;
  }

  .service-title{
    display: flex;
    height: 50%;
  }

  .service-title img{
    width: 20%;
    height:20%;
    background-color: salmon;
  }

  .right-title{
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    color: #000;
  }
  .right-english-title{
    text-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #999;
    font-size: 14px;
  }
  .service_right{
    width: 80%;
  }

  .right-bottom{
    margin-top: 10px;
    letter-spacing: 3px;
    text-align: left;
    font-size: 14px;
    color: #000;
  }

  


</style>
