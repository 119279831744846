<template>
    <div>
        <div class="bottom-box">
            <div class="left">
            <div class="left_title">
                网站只提供标书修改审核服务，标书制作请与客服联系
            </div>
            <div class="left_code">
                <div class="h5">
                <img :src="h5_logo" alt="">
                <div> 微信小程序</div>
                </div>
                <div class="wechat">
                <img :src="xcx_logo" alt="">
                <div> 客服二维码</div>
                </div>
            </div>
            </div>

            <div class="right">
            <img :src="logo" alt="">
            <div class="company">
                <div class="company_message">主办单位：DANBIRD丹鸟资讯有限公司</div>
                <div class="company_message">版权所有：DANBIRD丹鸟资讯有限公司拥有最终解释权</div>
                <div class="company_message">© 2020 Mockup.  All Rights Reserved.</div>

            </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
          logo:'',
          xcx_logo:'',
          h5_logo:''
        }
    },
    created(){
      this.loadLogo()
    },
    methods:{
      loadLogo(){
            this.$api.post('common.jshopconf',{},(res)=>{
                    this.logo = res.data.shop_logo
                    this.h5_logo = res.data.H5_erweima
                    this.xcx_logo = res.data.xcx_erweima
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    .bottom-box{
    padding-top: 30px;
    margin-top: 100px;
    height: 220px;
    background-color: slateblue;
    display: flex;
  }

  .left{
    margin-left: 415px;
  }
  .left_title{
    text-align: left;
    font-size: 26px;
    color:#FFF;
  }
  .left_code{
    display: flex;
    margin-top: 30px;
  }
  .h5{
    text-align: center;
    color: #FFF;
    font-size: 16px;
  }
  .h5 img{
    height: 130px;
    width: 130px;
    background-color: #FFF;
    border-radius: 10px;
  }
  .wechat{
    margin-left: 70px;
    text-align: center;
    color: #FFF;
    font-size: 16px;
  }
  .wechat img{
    height: 130px;
    width: 130px;
    background-color: #FFF;
    border-radius: 10px;
  }

  .right{
    text-align: left;
    margin-left: 410px;
  }

  .right img{
    margin-top: 10px;
    height: 67px;
    width: 216px;
    background-color: springgreen;
  }

  .company{
    margin-top: 40px;
  }
  .company_message{
    margin-bottom: 10px;
    color: #FFF;
    font-size: 14px;
    text-align: left;
    
  }

</style>