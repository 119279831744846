import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    is_login:false,
    show_resign:false,
    wx_id:'',
    code:'',
    show_login:false
  },
  mutations: {
    change_login(state,val){
      state.is_login = val
    },
    change_resign(state,val){
      state.show_resign = val
    },
    change_desclogin(state,val){
      state.show_resign = val
    },
    set_wxid(state,val){
      state.wx_id = val
    },
    set_code(state,val){
      state.code = val
    }

  },
  actions: {
  },
  modules: {
  }
})
