<template>
    <div>
        <div class="head">
            <img :src="logo" alt="" srcset="" class="logo">
            <div class="login"  v-if="has_login">
                <div class="login_text" @click="show_login = !show_login">
                    登录
                </div>
                <div class="login_text" @click="show_resign = !show_resign">
                    注册
                </div>
            </div>
            <div class="login" @click="get_user" v-else>
                {{user}}
            </div>
        </div>
            <!-- 导航 -->
            <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect" background-color="#144591" text-color="#FFF" active-text-color="#FFF" class="nav">
            <el-menu-item index="1" >首页</el-menu-item>
            <el-menu-item index="2" >专家/律师</el-menu-item>
            <el-menu-item index="3" >标书审查</el-menu-item>
            <el-menu-item index="4" >法律法规</el-menu-item>
            <el-menu-item index="5" >用户中心</el-menu-item>
            <el-menu-item index="6" >关于我们</el-menu-item>
            </el-menu>


            <!-- 轮播 -->
            <el-carousel :interval="2000" arrow="always">
            <el-carousel-item v-for="(item,index) in banner_list" :key='index'>
                <img :src="item.img" alt="" srcset="" @click="jump(item)" style="height:100%;width:100%;">
            </el-carousel-item>
        </el-carousel>




        <div class="mask" v-show="show_login" @click.self="show_login = !show_login">
            <div class="login-box">
                <div class="logo-second">
                    DANBIRD
                </div>
                <div class="enter">
                    <input type="number" placeholder="输入手机号" v-model="phone">
                </div>
                <div class="enter">
                    <input type="number" maxlength="6" placeholder="输入验证码" v-model="code">
                    <div @click="send_code" v-if="show_send" class="get-code">获取验证码</div>
                    <div class="get-code" v-else>{{time}}秒后重新发送</div>
                </div>
                <div class="login-submit" @click="login('code')">
                    登录
                </div>
                 <div class="login-submit" @click="change_login('word')">
                    密码登录
                </div>
                <!-- <div class="enter" style="border:none;color:#888;">
                    <el-divider>其他登录方式</el-divider>
                </div>-->
                <div class="other-login">
                    <!-- <img src="../assets/QQ.png" alt="" srcset="" @click="toLogin('qq')"> -->
                    <img src="../assets/wechat.png" alt="" srcset="" @click="toLogin('wx')">
                </div>
            </div>
        </div>
        <div class="mask" v-show="show_resign" @click.self="show_resign = !show_resign">
            <div class="login-box">
                <div class="logo-second">
                    DANBIRD
                </div>
                <div class="enter ">
                    <input type="number" placeholder="输入手机号" v-model="phone">
                </div>
                <div class="enter">
                    <input type="number"  maxlength="6" placeholder="输入验证码" v-model="code">
                    <div @click="send_code" v-if="show_send" class="get-code">获取验证码</div>
                    <div class="get-code" v-else>{{time}}秒后重新发送</div>
                </div>
                <div class="login-submit" @click="login('resign')">
                    注册并登录
                </div>
                 <div class="login-submit" @click="change_login('word')">
                    密码登录
                </div>
                <!-- <div class="enter" style="border:none;color:#888;">
                    <el-divider>其他登录方式</el-divider>
                </div> -->
                <div class="other-login">
                    <!-- <img src="../assets/QQ.png" alt="" srcset="" @click="toLogin('qq')"> -->
                    <img src="../assets/wechat.png" alt="" srcset="" @click="toLogin('wx')">
                </div>
            </div>
        </div>
        
        <div class="mask" v-show="show_word_login" @click.self="show_word_login = !show_word_login">
            <div class="login-box">
                <div class="logo-second">
                    DANBIRD
                </div>
                <div class="enter" >
                    <input type="number" placeholder="输入手机号" v-model="phone">
                </div>
                <div class="enter" >
                    <input type="password" placeholder="输入密码" v-model="password">
                    
                </div>
                <div class="login-submit" @click="login('word')">
                    登录
                </div>
                 <div class="login-submit" @click="change_login('code')">
                    验证码登录
                </div>
                <!-- <div class="enter" style="border:none;color:#888;">
                    <el-divider>其他登录方式</el-divider>
                </div> -->
                <div class="other-login">
                    <!-- <img src="../assets/QQ.png" alt="" srcset="" @click="toLogin('qq')"> -->
                    <img src="../assets/wechat.png" alt="" srcset="" @click="toLogin('wx')">
                </div>
            </div>
        </div>







    </div>
</template>

<script>
export default {
    data(){
        return{
            activeIndex: '1',
            show_login:false,
            show_word_login:false,
            show_resign:false,
            time:60,
            show_send:true,
            user_name:'',
            phone:'',
            code:'',
            password:'',
            has_login:true,
            user:'',
            banner_list:'',
            logo:''

        }
    },
    watch:{
        show_login(val){
            if(val){
                document.body.style.overflow = 'hidden'
            }else{
                document.body.style.overflow = ''
            }
        },
        show_word_login(val){
            if(val){
                document.body.style.overflow = 'hidden'
            }else{
                document.body.style.overflow = ''
            }
        },
          show_resign(val){
            if(val){
                document.body.style.overflow = 'hidden'
            }else{
                document.body.style.overflow = ''
            }
        },
        '$store.state.show_resign':function(){
            this.show_resign = true
        },
        '$store.state.show_login':function(){
            this.show_login = true
        }
    },
    created(){
        if(this.$local.get('userinfo')){
            this.has_login = false
            this.user = JSON.parse(this.$local.get('userinfo')).nickname
        }else{
            this.has_login = true
        }
        // if(this.$store.state.is_login){
        //     this.has_login = false
        //     this.user = this.$local.get('userinfo').username
        // }else{
        //     this.has_login = true
        // }
        this.loadBanner()
        this.loadLogo()
    },
    methods:{
        toLogin(item){
            if(item == 'wx'){
                window.location.href = 'https://open.weixin.qq.com/connect/qrconnect?appid='+this.$api.appid+'&redirect_uri='+encodeURIComponent(this.$api.return_uri)+'&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect'
                this.$message({type:'success',message:'微信'})
            }else{
                this.$message({type:'success',message:'QQ'})
            }
        },
        loadLogo(){
            this.$api.post('common.jshopconf',{},(res)=>{
                    this.logo = res.data.shop_logo
            })
        },
        change_login(value){
            if(value == 'word'){
                this.show_login = false,
                this.show_word_login = true
            }else{
                this.show_word_login = false,
                this.show_login = true
            }
        },
        // 轮播图点击事件
        jump(item){
            console.log(item)
        },
        // 加载轮播图
            loadBanner(){
                this.$api.post('advert.getAdvertList',{code:'tpl1_slider'},(res)=>{
                    if(res.data.status){
                        this.banner_list = res.data.data.list
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            },

        // 导航栏选择
        handleSelect(key, keyPath) {
            // console.log(key, keyPath);
            if(key == 1){
                this.$router.push({path:'/'})
            }
            else if(key == 2){
                this.$router.push({path:'/major'})
            }
            else if(key == 3){
                this.$router.push({path:'/shenhe'})
            }
            else if(key == 4){
                this.$router.push({path:'/artical_list'})
            }
            else if(key == 5){
                  this.$router.push({path:'/mine'})
            }else if(key == 6){
                this.$router.push({path:'/artical',query:{id:41}})
            }
        },
        // 发送验证码
        send_code(){
            let data= {
					mobile:this.phone,
					code:'login'
				}
            this.$api.post('user.sms',data,res =>{
                if(res.data.status){
                    this.$message({
                        message:'发送成功',
                        type:'success'
                    })
                    this.show_send = false
                    var interval = setInterval(() => {
                        if(this.time > 0){
                            this.time --
                        }else{
                            this.time = 60
                            this.show_send = true
                            clearInterval(interval)
                        }
                    }, 1000);
                }else{
                    this.$message.error(res.data.msg)
                }
            })
           
        },
        //登录
        login(value){
            if(value == 'code'){
            let data = {
                platform:1,
				mobile:this.phone,
				code:this.code
            }
            this.$api.post('user.smslogin',data,(res)=>{
                if(res.data.status){
                    this.$message({
                        message:'登录成功',
                        type:'success'
                    })
                    this.$store.commit('change_login',true)
                    this.$local.set('token',res.data.data)
                    this.$api.userpost('user.info',{},(res)=>{
                        if(res.data.status){
                            this.show_login = false
                            console.log(res.data.data)
                            this.user = res.data.data.username
                            this.$local.set('userinfo',JSON.stringify(res.data.data))
                            location.reload()
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
                }else{
                    this.$store.commit('change_login',false)
                    this.$message.error(res.data.msg)
                }
            })
            }else if(value =='word'){
                let data = {
                platform:1,
				mobile:this.phone,
				password:this.password
            }
            this.$api.post('user.login',data,(res)=>{
                if(res.data.status){
                    this.$message({
                        message:'登录成功',
                        type:'success'
                    })
                    this.$store.commit('change_login',true)
                    this.$local.set('token',res.data.data)
                    this.$api.userpost('user.info',{},(res)=>{
                        if(res.data.status){
                            this.show_login = false
                            console.log(res.data.data)
                            this.user = res.data.data.username
                            this.$local.set('userinfo',JSON.stringify(res.data.data))
                            location.reload()
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
                }else{
                    this.$store.commit('change_login',false)
                    this.$message.error(res.data.msg)
                }
            })
            }else{
                let data ={
					platform:2,
					mobile:this.phone,
					user_wx_id:this.$store.state.wx_id,
					code:this.code
				}
                this.$api.post('user.smslogin',data,(res)=>{
                if(res.data.status){
                    this.$message({
                        message:'登录成功',
                        type:'success'
                    })
                    this.$store.commit('change_login',true)
                    this.$local.set('token',res.data.data)
                    this.$api.userpost('user.info',{},(res)=>{
                        if(res.data.status){
                            this.show_login = false
                            this.user = res.data.data.username
                            this.$local.set('userinfo',JSON.stringify(res.data.data))
                            location.reload()
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
                }else{
                    this.$store.commit('change_login',false)
                    this.$message.error(res.data.msg)
                }
            })
            }
           
            // this.show_login = false
        },
        get_user(){
            this.$router.push({path:'/mine'})
        }
    
    }
}
</script>

<style lang="scss" scoped>
.head{
    height:122px;
    display: flex;
    align-items: center;
  }

  .logo{
    height: 67px;
    width: 216px;
    margin-left: 383px;
  }

  .login{
    display: flex;
    margin-left: 836px;
  }

  .login_text{
    margin-right: 20px;
    color: #144591;
    font-size: 16px;
  }

  .nav{
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }

  .mask{
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      height: 100%;
      width: 100%;
      background: rgba($color: #000000, $alpha: .3);
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  .login-box{
      height: 798px;
      width: 1614px;
      background-image: url('../assets/login.png');
  }

  .logo-second{
      font-size: 48px;
      color: #144591;
      height: 67px;
      width: 216px;
      margin-left: 69.0625rem;
      margin-top: 5.9375rem;
  }

  .enter{
      width: 553px;
      height: 88px;
      border: 1px #DCDCDC solid;
      border-radius: 10px;
      margin-top: 32px;
      margin-left: 932px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1rem;
      input{
          border: none;
          margin-left: 1.875rem;
          height: 88px;
          font-size: 20px;
      }
      input:focus{
          outline: none;
      }
  }

  .get-code{
      color: #3381ff;
      margin-right: 30px;
  }
  .login-submit{
        width: 553px;
        height: 88px;
        background: linear-gradient(90deg, #6FB2FF 0%, #3C89FF 58%, #095FFF 100%);
        box-shadow: 0px 7px 27px rgba(50, 129, 255, 0.51);
        opacity: 1;
        border-radius: 10px;
        color:#FFF;
        font-size: 20px;
        line-height: 88px;
        text-align: center;
        margin-left: 932px;
        margin-top: 30px;
  }

  .other-login{
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 553px;
      height: 88px;
      margin-left: 932px;
    //   margin-left: 500px;

  }

  .height_five{
      height: 50px;
  }
</style>