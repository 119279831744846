const set = function(key,value){
    localStorage.setItem(key,value)
}
const get = function(key){
    return localStorage.getItem(key)
}
const del = function(key){
    localStorage.removeItem(key)
}
const clear = function(){
    localStorage.clear()
}

export{
    set,
    get,
    del,
    clear
}